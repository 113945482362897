<template>
  <core-section id="what-ive-written">
    <abstract-3 />

    <core-heading>What I've written</core-heading>

    <v-col cols="12">
      <v-carousel
        :cycle="true"
        :height="schema.projectsCarousel * this.carouselHeight"
        :show-arrows="false"
        class="elevation-0"
        hide-delimiter-background
      >
        <v-carousel-item v-for="(publication, i) in schema.publications" :key="i">
          <publication :value="publication" />
        </v-carousel-item>
      </v-carousel>
    </v-col>
  </core-section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'WhatIveWritten',

  components: {
    Abstract3: () => import('@/components/Abstract3'),
    Publication: () => import('@/components/Publication')
  },
  computed: {
    ...mapState('app', ['schema']),
    carouselHeight () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '870'
        case 'sm': return '750'
        case 'md': return '770'
        case 'lg': return '650'
        case 'xl': return '600'
      }
      return '1000'
    }
  }
}
</script>
